/** @format */
import img1 from "../src/images/market.webp";
import img2 from "../src/images/buying.webp";
import img3 from "../src/images/analyzer.webp";

const ChooseUs = () => {
  const handleClick = () => {
    const section = document.getElementById("mySection");
    section.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div>
        <div>
          <h2>Here is what you will learn:</h2>
        </div>
        <div>
          <ul>
            <li>
              <p>
                How to <strong>FIND </strong> the Best deals with the largest{" "}
                <strong>PROFIT </strong>potential!
              </p>
            </li>
            <li>
              <p>
                <strong>FUND </strong> deals using <strong>NONE </strong>of your
                money
              </p>
            </li>
            <li>
              <p>
                How to <strong>ANALYZE </strong> the deal quickly & easily using
                my deal analysis tools
              </p>
            </li>
            <li>
              <p>
                <strong>FIX </strong>properties with confidence to add value to
                any deal
              </p>
            </li>
            <li>
              <p>
                <strong>FLIP </strong> those properties quickly by Wholesaling
                or Rehab properties using my proven process
              </p>
            </li>
            <li>
              <p>
                The step by step way to acquire <strong>CASH FLOW</strong>{" "}
                properties to build your own rental portfolio!
              </p>
            </li>
            <li>
              <p>
                How to successfully invest <strong>PART TIME</strong>
              </p>
            </li>
          </ul>
        </div>
      </div>
 
      {/* second section*/}
      <div className="choose-us-investors">
        <h2>
          <strong>BONUS GIFTS!</strong>
        </h2>
        <p>
          Also when you ATTEND the class you receive these 3 powerful resources:
        </p>
      </div>

      <div className="benifits">
        <div className="inner-benifit">
          <img src={img1} alt="logo" />
          <h3>OFF MARKET DEALS</h3>
          <p>Secrets to finding off market deals manual</p>
        </div>
        <div className="inner-benifit">
          <img src={img3} alt="logo" />
          <h3>DEAL ANALYZER TOOL</h3>
          <p>
            The Deal Analyzer is the tool I use to analyze every deal to check
            my profit potential
          </p>
        </div>

        <div className="inner-benifit">
          <img src={img2} alt="logo" />
          <h3>FUNDING FOR DEALS</h3>
          <p>My List of lenders you can use to fund your flips</p>
        </div>
        
      </div>
    <button onClick={handleClick} className="about-us-btn">Register NOw</button>
    </>
  );
};

export default ChooseUs;
