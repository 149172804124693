/** @format */

const Footer = () => {
  return (
    <footer>
      <div className="footer-inner">
        <p>Copyright @ 2024. Multifamily RealSuccess Investments.</p>
        <p>
          We offer free workshops and advanced education. We do not partner up
          with investors or sell a franchise or a done-for-you business.
        </p>
        <p>
          Learning real estate takes time and energy, we do not believe people
          can become wealthy in just a few months of investing in real estate.
          It takes effort, commitment, timing on the market, knowledge, systems
          and many other factors. We are not financial consultants and do not
          guarantee earnings or returns on investment. We have no license to
          offer financial, tax, or legal advice. Investing can be risky and may
          result in partial or total loss.
        </p>
        <p>
          This site is not endorsed by Facebook, Instagram, Meta, Google or
          Youtube in any way, shape or form. Multifamily RealSuccess Investments is a real
          estate investment company committed to create active and passive
          income for our investors and people we do business with.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
