/** @format */
 
import { useRoutes } from "react-router-dom";
import Home from "./Home"; 
import "bootstrap/dist/css/bootstrap.min.css";
import ThankYou from "./ThankYou.js";

export default function App() {
	return useRoutes([
		{
			path: "/",
			element: <Home />,
		},
		{
			path: "/thanks",
			element: <ThankYou />,
		},
		{ path: '/404', element: <ThankYou /> },
		{ path: "*", element: <Home to="/404" replace /> },
		
	]);
}
